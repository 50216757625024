/* LastContact.css */

.containerLC {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(242, 245, 247);
  padding: 20px; /* Add padding for spacing */
  top: 750px;
  height: 450px;
}

.hyperlinksLC {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 200px;
  top: 130px;
  line-height: 27px;
}

.hyperlinksLC a {
  margin-bottom: 8px;
  text-decoration: none;
}

.hyperlinksLC a:hover {
  text-decoration: underline;
  color: #c90c0c;
}

.ConHeadLC {
  /* useful links */
  position: absolute;
  font-size: 16px;
  color: black;
  width: 150px;
  top: 60px;
  left: 150px;
  text-transform: uppercase;
}

.ConHeadLC::first-letter {
  color: #de761b;
  font-size: 150%;
}

.text-galleryLC {
  width: 17%;
  height: 240px;
  overflow: hidden;
  position: relative;
  cursor: default; /* Default cursor style */
  right: 220px;
  bottom: 60px;
}

.text-galleryLC:active {
  cursor: grab; /* Cursor style during dragging */
}

.slider-headingLC {
  position: absolute;
  right: 310px;
  bottom: 355px;
  font-size: 24px;
  text-transform: uppercase;
}

.slider-headingLC::first-letter {
  color: #de761b;
  font-size: 150%;
}

.text-slideLC {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none; /* Prevent text selection */
  transform-origin: left center; /* Set transform origin for rotation */
  transform: scale(1); /* Initial scale for the first slide */
  transition: transform 0.5s ease-in-out; /* Smooth transition for slides */
}

.activeLC {
  transform: scale(0.8) rotateY(-20deg); /* Initial transform for the first slide */
}

.text-slideLC h3 {
  position: relative;
  bottom: 40px;
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
}

.text-slideLC p {
  margin: 0;
  padding: 10px;
  top: 100px;
}

/* Styled Word Class */
.styled-word {
  color: red; /* Example styling */
  font-weight: bold;
}

/* Styles for the email form and right arrow button */

.contentLC {
  position: relative;
  height: 400px;
  width: 500px; /* Adjust width as needed */
  left: 200px; /* Remove left positioning to align with the container */
  overflow: hidden; /* Prevent horizontal scroll */
}

.HeadingLC {
  position: absolute;
  font-size: 24px;
  top: 0px;
  left: 60px;
  text-transform: uppercase;
}

.HeadingLC::first-letter {
  color: #de761b;
  font-size: 150%;
}

.ParaLC {
  position: relative;
  height: 100px;
  width: 400px;
  left: 60px;
  top: 80px;
  text-align: left;
}

.input-containerLC {
  /* input Container */
  position: absolute;
  left: 60px;
  top: 220px;
  display: inline-flex;
  height: 35px;
  border: none;
  color: rgb(242, 245, 247);
}

input[type="email"] {
  padding-right: 40px; /* Adjust as needed */
  width: 300px;
  border: none;
  background-color: rgb(242, 245, 247);
}

input[type="email"]:focus {
  outline: none; /* Remove default outline */
}

.arrow-buttonLC {
  position: absolute;
  top: 50%;
  left: 320px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.arrow-buttonLC i {
  font-size: 18px; /* Adjust icon size as needed */
}

.slide-indicatorLC {
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  right: 350px;
}

.indicator-dotLC {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #242121;
  margin: 0 5px;
  cursor: pointer;
}

.indicator-dotLC.active {
  background-color: #de761b;
}

.minusLC {
  position: relative;
  bottom: 130px;
  z-index: 1;
  left: 190px;
  transform: scaleX(8);
  opacity: 0.5;
  color: #de761b;
}

.minusLCC {
  position: relative;
  top: 65px;
  z-index: 1;
  right: 140px;
  transform: scaleX(8);
  opacity: 0.5;
  color: #de761b;
}

.minusLCCC {
  position: relative;
  bottom: 125px;
  z-index: 1;
  left: 120px;
  transform: scaleX(10);
  opacity: 0.5;
  color: #de761b;
}

.minusLCCCC {
  position: relative;
  top: 90px;
  z-index: 1;
  right: 20px;
  transform: scaleX(28);
  opacity: 0.5;
  color: #de761b;
}
