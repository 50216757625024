:root {
  --main-color: #FF0000; /* Define the color variable */
}
.container {
  position: relative;
  width: 1440px;
  height: 730px; /* Adjust height as needed */
  left: 20px;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mainBg {
  position: absolute;
  top: 90px;
  left: 150px;
  width: 650px; /* Adjust width as needed */
  height: 550px;
  box-shadow: 0 0 10px rgba(70, 63, 63, 0.5);
  object-fit: cover;
}

.sideBg {
  position: absolute;
  top: 90px;
  right: 150px;
  width: 650px; /* Adjust width as needed */
  height: 450px;
  box-shadow: 0 0 10px rgba(231, 167, 77, 0.8);
  object-fit: cover;
}

.text-gallery {
  position: absolute;
  top: 160px;
  left: 55%; /* Changed from 55% to 50% for center alignment */
  transform: translateX(-50%);
  width: 330px;
  height: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: center;
  transition: opacity 0.3s ease; /* Adding transition effect for opacity */
  cursor: default; /* Default cursor */
  user-select: none; /* Disable text selection */
}

.text-gallery:active {
  cursor: grabbing; /* Cursor when clicked */
}

.text-gallery h2 {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
  top: 45px;
  right: 90px;
  position: absolute;
  font-weight: 800;
}
.text-gallery h2::first-letter {
  font-size: 150%; /* Example: increase font size of first letter */
  font-weight: bold; /* Example: bold font for first letter */
  color: #f47c0b;
}

.text-gallery p {
  font-size: 18.5px;
  color: #555;
  position: absolute;
  top: 110px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
}

.text-gallery p::first-letter {
  font-size: 150%;
  font-weight: 900;
  color: #f47c0b;
}

.slide {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
}

.slide.active {
  opacity: 1;
}

.logofe {
  position: absolute;
  width: 50px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  left: 150px; /* Adjust left position */
  top: 10px; /* Adjust top position */
  z-index: 1; /* Ensure logo appears above other elements */
  opacity: .6;
}

.slide .logofe { /* Changed from .rocket-icon to .logo */
  position: absolute;
  width: 350px; /* Adjust size as needed */
  height: 350px;
  margin-right: 10px;
  color: #FFD700;
  left: 16px;
  top: 10px;
}

.slide h2 {
  margin: 0;
}

.slide-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.indicator-dot.active {
  background-color: #de761b;
}
