.image-gallery-container {
  position: relative;
  width: 1460px; /* Adjust max-width as needed */
  height: 586px;
  overflow: hidden;
  animation: fadeIn 1s ease forwards;
  margin: 0 auto;
  top: 58.5px;
  left: 5px;
}

.parent-image-container {
  position: relative;
  overflow: hidden;
  animation: fadeIn 1s ease forwards; 
}

.main-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease;
}

.overlay-image {
  position: absolute;
  display: block;
  top: 80px;
  height: 450px;
  left: 100px;
  opacity: 10; /* Initial opacity to hide overlays by default */
  transition: opacity 0.5s ease;
}

.overlay-text {
  position: absolute;
  bottom: 400px;
  left: 750px;
  text-align: left;
  color: black;
  background-color: transparent;
  padding: 5px 10px;
  z-index: 1;
  transition: opacity 0.5s ease;
  opacity: 10; /* Initial opacity to hide text by default */
}

.readmore-button {
  position: absolute;
  bottom: 190px;
  right: 575px;
  padding: 5px 10px;
  background-color: #f47c0b;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  height: 40px;
  width:130px;
}
.readmore-button:hover{
  color: #f47c0b;
  background-color: white;
}

.navigation-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.slide-indicators {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px; /* Adjust the position from the bottom as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
}

.slide-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 5px;
  cursor: pointer;
}

.slide-indicator.active {
  background-color: #f47c0b;
}


.prev-button,
.next-button {
  padding: 5px 10px;
  background-color: #f47c0b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.prev-button:hover,
.next-button:hover,
.readmore-button:hover {
  background-color: #ff8c00;
}

.arrow-icon {
  font-size: 30px;
  color: white;
  background-color: rgba(59, 54, 54, 0.35);
  padding: 10px;
  height: 30px;
  border-radius: 10%;
  cursor: pointer;
  position: relative;
}

.arrow-icon:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.left-arrow {
  margin-right: 20px;
position: absolute;
top: 250px;
left: 40px;
}

.right-arrow {
  margin-left: 20px;
  position: absolute;
  top: 250px;
  right: 40px;
}

.additional-text {
  position: absolute;
  bottom: 250px;
  left: 750px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: black;
  background-color: transparent;
  padding: 5px 10px;
  z-index: 1;
  transition: opacity 0.5s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.text-animation-1 {
  animation: slideInFromLeft 1s ease forwards;
}

.text-animation-2 {
  animation: fadeIn 1s ease forwards;
}

.text-animation-3 {
  animation: slideInFromRight 1s ease forwards;
}

.additional-text::first-letter {
  font-size: 150%; /* Example: increase font size of first letter */
  font-weight: bold; /* Example: bold font for first letter */
  color: #f47c0b; /* Example: different color for first letter */
}
.image111{
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 800;
}
.image222{
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 800;
  
}
.image333{
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 800;
}
.image444{
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 1000;
  color: #f47c0b;
}
.image555{
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 1000;
  color: #f47c0b;
}
.image666{
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 1000;
  color: #f47c0b;
}