.heading1{
    position: absolute;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
}
.workdoneh1{
    position: absolute;
    top: 60px;
    font-size: 34px;
    font-weight: 900;
}
.workdoneh1::first-letter{
    color: orange;
    font-size: 150%;
    font-weight: 900;
}
.container1 {
    color: black;
    position: relative;
    background-color: rgb(242, 245, 247);
   left: 10px;
    height: 745px;
    width: 1460px;
}

.square1 {
    position: absolute;
    width: 280px;
    height: 400px;
    border: 1px solid black;
    padding: 10px;
    box-sizing: border-box;
    left: 65px;
    top: 210px;
    border-radius: 5%;
}
.square2 {
    position: absolute;
    width: 280px;
    height: 400px;
    border: 1px solid black;
    padding: 10px;
    box-sizing: border-box;
    left: 400px;
    top: 210px;
    border-radius: 5%;
}
.square3 {
    position: absolute;
    width: 290px;
    height: 400px;
    border: 1px solid black;
    padding: 10px;
    box-sizing: border-box;
    left: 790px;
    top: 210px;
    border-radius: 5%;
}
.square4 {
    position: absolute;
    width: 280px;
    height: 400px;
    border: 1px solid black;
    padding: 10px;
    box-sizing: border-box;
    left: 1120px;
    top: 210px;
    border-radius: 5%;
}
.container1 h2{
    font-size: 16px;
    color: orange;
    font-weight: 700;
    line-height: 28px;
}
.workdoneh21{
    font-size: 20px;
    font-weight: 800;
    color: black;
}
.workdoneh22{
    font-size: 20px;
    font-weight: 800;
    color: black;
}
.workdoneh23{
    font-size: 20px;
    font-weight: 800;
    color: black;
}
.workdoneh24{
    font-size: 20px;
    font-weight: 800;
    color: black;
}
.container1 p{
    position: absolute;
    top: 120px;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    text-transform: lowercase;
}
.container1 p::first-letter{
    color: orange;
    font-size: 150%;
}
.container1 p::first-line{
    color: rgb(236, 155, 3);
}
.king{
    text-transform: uppercase;
}
.workdoneh21:hover,.workdoneh22:hover,.workdoneh23:hover,.workdoneh24:hover{
    color: orange;
    cursor: pointer;
}
.logooWD{
    opacity: 0.060;
}
.chevronup{
    position: absolute;
    z-index: 1;
    top: 150px;
    height: 30px;
    color: rgb(0, 0, 0);
    transform: rotate(180deg);
}
.minusWD{
    position: absolute;
    left: 130px;
    top: 120px;
    transform: scaleX(9);
    opacity: .2;
}
.minussWD{
    position: absolute;
    top: 160px;
    left: 560px;
    z-index: 1;
    transform: scaleX(19);
    opacity: .2;
}
.minusssWD{
    position: absolute;
    top: 160px;
    z-index: 1;
    right: 525px;
    transform: scaleX(19);
    opacity: .2;
}