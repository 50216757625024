h1 {
    position: relative;
    top: 150px;
    left: 35px;
    z-index: 1;
    width: 1420px;
}
.logoBg{
    position: relative;
  
  width: 1450px; /* Adjust max-width as needed */
  height: 705px;
  top: 0px;
  left: 5px;
  opacity: .5;
}
.ilogo{
    position: absolute;
    z-index: 1;
    top: 845px;
    left: 585px;
    height: 45px;
    opacity: 0.6;
}
.ilogo:hover{
    color: orange;
    transition: orange 2s ease;
    cursor: pointer;
}
.headLogo:hover{
color: orange;
cursor: pointer;
}
.chevronupLP{
    position: absolute;
    z-index: 1;
    top: 900px;
    height: 30px;
    color: rgb(0, 0, 0);
    transform: rotate(180deg);
}
.minusLP{
    position: absolute;
    top: 910px;
    z-index: 1;
    right: 535px;
    transform: scaleX(19);
    opacity: .5;
}
.minussLP{
    position: absolute;
    top: 910px;
    left: 560px;
    z-index: 1;
    transform: scaleX(19);
    opacity: .5;
}
/*Clients Logo Positioning */

.afi{
    position: absolute;
    z-index: 1;
    left: 300px;
    top: 950px;
    opacity: 1;
}
.amar{
    position: absolute;
    z-index: 1;
    left: 480px;
    top: 950px;
    opacity: 1;
}
.bd{
    position: absolute;
    z-index: 1;
    left: 660px;
    top: 950px;
    opacity: 1;
}
.cavin{
    position: absolute;
    z-index: 1;
    left: 830px;
    top: 950px;
    opacity: 10;
}
.chak{
    position: absolute;
    z-index: 1;
    left: 1000px;
    top: 950px;
    opacity: 1;
}
.iig{
    position: absolute;
    z-index: 1;
    left: 300px;
    top: 1070px;
    opacity: 10;
}
.in{
    position: absolute;
    z-index: 1;
    left: 480px;
    top: 1070px;
    opacity: 10;
}
.nour{
    position: absolute;
    z-index: 1;
    left: 660px;
    top: 1070px;
    opacity: 10;
}
.piri{
    position: absolute;
    z-index: 1;
    left: 830px;
    top: 1070px;
    opacity: 10;
}
.primetec{
    position: absolute;
    z-index: 1;
    left: 1000px;
    top: 1070px;
    opacity: 10;
}
.shel{
    position: absolute;
    z-index: 1;
    left: 300px;
    top: 1200px;
    opacity: 10;
}
.skating{
    position: absolute;
    z-index: 1;
    left: 480px;
    top: 1200px;
    opacity: 10;
}
.tvs{
    position: absolute;
    z-index: 1;
    left: 660px;
    top: 1200px;
    opacity: 10;
}