.main-containerI{
position: relative;
height: 760px;
width: 1440;
background-color: transparent;
top: 725px;
line-height: 23px;
}
.HeadingImp{
    position: absolute;
    height: 30px;
    width: 300px;
    left: 800px;
    top: 70px;
    text-transform: uppercase;
    font-weight: 900;
}
.arrow1{
    position: absolute;
    top: 60px;
    right: 320px;
    height: 180px;
    width: 60px;
    opacity: .08;
    transform: scaleX(0.8);
}
.arrow2{
    position: absolute;
    bottom: 30px;
    right: 620px;
    height: 180px;
    width: 60px;
    opacity: .08;
    transform: scaleX(0.6);
    transform: rotate(90deg);
}
.arrow3{
    position: absolute;
    top: 70px;
    right: 620px;
    height: 180px;
    width: 60px;
    opacity: .08;
    transform: scaleX(0.6);
    transform: rotate(90deg);
}
.HeadingImp::first-letter{
    color: orange;
    font-size: 150%;
}
.bgImp{
    opacity: 20;
    width: 1440px;
    height: 760px;
   
}
.containerI1{
position: absolute;
height: 280px;
width: 500px;
border: .08px solid black;
top: 100px;
left: 50px;
text-align: left;
padding: 8px;
border-radius: 5%;
}
.containerI2{
position: absolute;
height: 350px;
width: 530px;
border: .08px solid black;
right: 100px;
top: 240px;
padding: 8px;
text-align: left;
border-radius: 5%;
}
.containerI3{
position: absolute;
height: 270px;
width: 500px;
border: .08px solid black;
bottom: 15px;
left: 50px;
text-align: left;
padding: 8px;
border-radius: 5%;
}
.HeadI1{
font-size: 18px;
position: absolute;
padding: 4px;
font-weight: 700;
text-transform: uppercase;
top: -12px;
}
.HeadI1::first-letter{
    color: orange;
    font-size: 150%;
}
.HeadI2{
    font-size: 18px;
    position: absolute;
    padding: 4px;
    font-weight: 700;
    text-transform: uppercase;
    top: -12px;
}
.HeadI2::first-letter{
    color: orange;
    font-size: 150%;
}
.HeadI3{
    font-size: 18px; 
    position: absolute;
    padding: 4px;
    font-weight: 700;
    text-transform: uppercase;
    top: -12px;
}
.HeadI3::first-letter{
    color: orange;
    font-size: 150%;
}
.ParaI1{
    font-size: 17px;
    position: absolute;
    top: 60px;
    font-weight: 450;
}
.ParaI2{
    font-size: 17px; 
    position: absolute;
    top: 40px;
    font-weight: 450;
}
.ParaI3{
    font-size: 17px;
    position: absolute;
    top: 40px;
    font-weight: 450;
}
.logoimpp {
    position: absolute;
    z-index: 1;
    height: 260px;
    width: 260px;
    right: 650px;
    top: 280px;
    opacity: 0.08; /* Set initial opacity to 0 for the fade-in effect */
   /* animation: fadeIn 2s ease-in-out forwards;  */
}
/*
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 0.09; }  
} */