*{
    letter-spacing: .5px;
}

.horizontal-nav {
    display: flex;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    position: relative;
    height: 75px;
    width:1420px;
    top: 58.5px;
    left:20px; 
   
}

.horizontal-nav ul {
    list-style-type: none;
    display: flex;
    font-size: 16px;
    margin: 0;
    padding: 23px;
    position: absolute;
    left:500px ;
   
}

.horizontal-nav li {
    margin-right: 10px;
}

.horizontal-nav a {
    color: rgb(38, 31, 31);
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
}

.horizontal-nav a:hover {
    color: #f47c0b;
    background-color: #f8fafa;
    transition: ease .5s;
}
.button-contact {
    color: rgb(255, 255, 255);
    background-color: #f47c0b;
    border: 2px solid #f47c0b;
    height: 40px;
    padding: 5px 30px 5px 10px; /* Adjust padding to make space for the icon */
    border-radius: 50px;
    position: absolute;
    right: 70px;
    top: 24px;
    cursor: pointer;
    display: flex;
    justify-content: space-between; /* Align text and icon */
    align-items: center;
    
  }
  
  .button-contact b {
    margin-right: 10px; /* Space between text and icon */
  }
  
  .button-contact:hover {
    background-color: white;
    color: #f47c0b;
    transition: ease 0.3s;
  }
  
  .button-contact svg {
    fill: rgb(255, 255, 255); /* Set the color of the arrow icon */
    height: 20px; /* Adjust icon size */
    width: 20px;
  }
  
.logo{
    height: 10px;
    width: 50px;
    margin-left: 160px;
    position: absolute;
    top: 70px;
    opacity: 5;
}