@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Info Styling */

.top-header{
    
    border: 10px;
    background-color: rgb(4, 4, 87);
    height: 48px;
    width:1460px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.info{
    padding-left: 5px;
    font-size: 14px;
    position: absolute;
    left: 165px;
    top: 5px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    transition: rgb(255, 255, 255) 1s ease;
}
.info:hover{
    color: orange;
    cursor: pointer;
}
.contact{
    color: white;
}
.side-info{
    font-family: "Roboto",sans-serif;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    right: 150px;
    top: 15px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    text-transform: uppercase;
}
.side-info::before{
    color: white;
}
.side-info:hover{
    color: orange;
    transition: orange 2s ease;
    cursor: pointer;
}

.phone-icon {
    color: orange;
    position: absolute;
    left: 150px;
    top: 18px;
    animation: vibrate 2s linear infinite;
  }
  
  @keyframes vibrate {
    0%, 20%, 40%, 60%, 80%, 100% {
      transform: translateX(0) translateY(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-1px) translateY(-1px);
    }
  }
  .phone-icon:hover{
color: aliceblue;
  }
  .globe {
    color: orange;
    position: absolute;
    right: 605px;
    top: 15px;
  }
  .spinning-globe {
    display: inline-block;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  